/* .ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}
 */
.Background-password {
  width: 100%;
  height: 150vh;
  margin-top: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RedefineContent {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 400px;
  height: max-content;
  border-radius: 12px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  background: rgb(250, 250, 250);
  transition: all 0.25s ease;
  position: absolute;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.closeBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.btn-submit {
  cursor: pointer;
  background-color: darkcyan;
  border: 0;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 3px 3px 5px rgb(122, 122, 122);
}

.btn-submit button:hover {
  transition: 0.5s;
  background-color: dodgerblue;
}

.form-Redefine {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.form-Redefine h2 {
  margin-bottom: 20px;
}

.form-Redefine h4 {
  padding: 3% 0;
  font-weight: 600;
  width: 80%;
}

.form-Redefine input {
  width: 80%;
  margin-top: 30px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid black;
  user-select: none;
  background-color: rgb(250, 250, 250);
}

.esqueci-senha {
  text-align: start;
  width: 80%;
  padding-top: 3%;
  color: blue;
  text-decoration: underline;
  font-size: 13px;
  cursor: pointer;
}

.form-Redefine button {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 40px 10px 40px;
  color: white
}

.form-Redefine button span a {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.error {
  font-size: 14px;
  color: red;
}

.error-span {
  width: 80%;
  font-size: 14px;
  background-color: rgb(255, 0, 0, 0.45);
  color: black;
  font-weight: 600;
  padding: 5px 20px;
}

@media (max-width: 480px) {
  .RedefineContent {
    width: 280px;
    height: max-content;
    border-radius: 12px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
}