.Navbar {
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1;
}

.Navbar-collapsed {
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1;
}

.navbar-menu {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 80px;
}

.Navbar .button-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar--logo {
    width: max-content;
    min-width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-plano {
    width: 90px;
    margin-inline: auto;
    margin-left: 5%;
}


.logo-prefeitura {
    width: 120px;
    margin-inline: auto;
    margin-left: 2%;
}

.navbar--features {
    display: flex;
    flex-direction: column;
    width: 76%;
    align-items: center;
    justify-content: center;
}

.navbar--features a {
    cursor: pointer;
    color: #ce6d13;
    text-decoration: none;
    margin: 0 20px;
}

.navbar--features a:hover {
    text-decoration: underline;
}

.navbar--login {
    display: flex;
    width: 24%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.navbar--login-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar--login button {
    width: max-content;
    cursor: pointer;
    color: #054679;
    background-color: transparent;
    text-decoration: none;
    border: 1px solid #054679;
    padding: 7px 10px;
    border-radius: 5px;
    transition: 0.5s
}

.navbar--login button:hover {
    color: white;
    background-color: #005e72;
    transition: 0.5s
}

.nav-button {
    margin-right: 10px;
}

.nav-user {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.nav-user button {
    background-color: rgb(223, 232, 235);
}

.nav-name {
    width: 60%;
}

.link {
    cursor: pointer;
}

.link-blue {
    width: max-content;
    text-align: center;
    font-size: 14px;
}

.list {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggler {
    display: none;
}

.toggler .toggler-checkbox {
    display: none;
}

.bar {
    display: none;
}

@media (max-width: 1158px) {
    .navbar--login {
        display: flex;
        width: auto;
        justify-content: center;
    }

    .navbar--logo .logo-prefeitura {
        width: 100px;
    }

    .navbar--logo .logo-plano {
        width: 80px;
    }

    .list {
        display: flex;
        flex-direction: row;
    }

    .list li {
        text-align: center;
    }

    .navbar--features a {
        margin: 0 .5em;
    }


    .navbar--login-buttons {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        padding: 0 2%;
    }

    .toggler .toggler-checkbox {
        display: none;
    }

}



@media (max-width: 768px) {
    .toggler {
        padding: 1% 100%;
    }

    .navbar--logo .logo-prefeitura {
        width: 120px;
    }

    .navbar--logo .logo-plano {
        width: 85px;
    }

    .navbar--login {
        display: flex;
        width: 70%;
        justify-content: center;
    }

    /* Open */

    .Navbar {
        height: max-content;
    }

    .Navbar .list {
        flex-direction: column;
    }

    .Navbar .list li {
        font-weight: 400;
        margin-bottom: 2%;
        text-align: center;
        width: max-content;
    }

    .Navbar .navbar--login {
        margin: 2% auto;
        padding: 0;
    }

    .Navbar .navbar--login-buttons {

        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        margin: auto;
    }

    .Navbar .navbar--login-buttons .nav-button {
        margin-bottom: 0;
    }

    .Navbar .nav-user .nav-name {
        word-break: break-all;
        max-width: 100%;
    }

    .Navbar .navbar--features {
        display: flex;
        width: max-content;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .navbar--logo {
        margin: .5em auto;
    }

    .navbar-menu {
        flex-direction: column;
        height: max-content;
    }

    .Navbar .navbar-menu label {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    .Navbar .toggler {
        display: block;
        transform: rotate(180deg);
    }

    /* closed */

    .Navbar-collapsed {
        max-height: max-content;
        width: 100%;
        display: flex;
    }

    .Navbar-collapsed .toggler {
        display: block;
    }

    .Navbar-collapsed .navbar-menu {
        width: 100%;
    }

    .Navbar-collapsed .navbar--features {
        display: none;
    }

    .Navbar-collapsed .navbar--login {
        display: none;
    }

    .Navbar-collapsed .navbar--logo {
        margin: 1em auto 0 auto;
    }
}