* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.center {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 2%;
  display: flex;
  flex-wrap: wrap;
}

html {
  scroll-behavior: smooth;
  transition: 10s all;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 0.4em;
  background-color: #a6aaaa64;
  border-radius: 10px;
}

/* Altera a cor do thumb */
body::-webkit-scrollbar-thumb {
  background-color: #ce6d13;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #000000;
  border-radius: 10px;
}