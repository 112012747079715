.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.Background-register {
  width: 100%;
  height: 150vh;
  margin-top: 40%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RegisterContent {
  width: 400px;
  height: max-content;
  border-radius: 12px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  top: 50%;
  right: 50%;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  background: rgb(250, 250, 250);
  transition: all 0.25s ease;
  position: absolute;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 10px rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.closeBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.btn-submit {
  background-color: darkcyan;
  border: 0;
  border-radius: 5px;
}

.btn-submit:hover {
  background-color: dodgerblue;
}

.RegisterContent .form-login {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.RegisterContent .form-login h2 {
  margin-bottom: 20px;
}

.RegisterContent .form-login input {
  width: 80%;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid black;
  user-select: none;
  background-color: rgb(250, 250, 250);
}

.RegisterContent .form-login button {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 40px 10px 40px;
  color: white
}

.RegisterContent .form-login span a {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.error {
  font-size: 14px;
  color: red;
}

.error-span {
  width: 80%;
  font-size: 16px;
  background-color: rgb(255, 0, 0, 0.45);
  color: black;
  font-weight: 600;
  padding: 5px 20px;

}


@media(max-width: 900px) {
  .Background-register {
    margin-top: 55%;
  }
}

@media (min-width: 621px) and (max-width: 768px) {
  .Background-register {
    margin-top: 40%;
  }
}

@media (min-width: 481px) and (max-width: 620px) {
  .Background-register {
    margin-top: 45%;
  }
}

@media (min-width: 401px) and (max-width: 480px) {
  .Background-register {
    margin-top: 55%;
  }
}

@media (max-width: 400px) {
  .Background-register {
    margin-top: 70%;
  }
}


@media (max-width: 768px) {
  .RegisterContent {
    width: 360px;
    height: max-content;
    border-radius: 12px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .RegisterContent .error {
    font-size: 12px;
  }

  .RegisterContent .form-login h2 {
    margin-bottom: 0;
  }

  .RegisterContent .form-login .btn-submit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .RegisterContent {
    width: 300px;
    height: max-content;
    border-radius: 12px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .RegisterContent .error {
    font-size: 12px;
  }

  .RegisterContent .form-login h2 {
    margin-bottom: 0;
  }

  .RegisterContent .form-login .btn-submit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .RegisterContent {
    width: 240px;
    height: max-content;
    border-radius: 12px;
    background-color: rgb(250, 250, 250);
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .RegisterContent .error {
    font-size: 12px;
  }

  .RegisterContent .form-login h2 {
    margin-bottom: 0;
  }

  .RegisterContent .form-login .btn-submit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}