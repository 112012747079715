* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.PollHome {
    padding-top: 78.25px;
    /* background-image: url("./../../../assets/images/capa-cidade.png"); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    background-position: top;
    background-size: cover;
}

.blue {
    color: blue;
}

.PollHome .poll {
    text-align: center;
    padding: 5% 10%;
    background-color: #d9dee0ef;
    border-radius: 20px;
    box-shadow: 4px 6px 2px 2px rgb(150, 140, 140);

}

.poll .poll-title {
    margin-bottom: 50px;
}

.poll .poll-text {
    margin-bottom: 30px;
}

.poll .poll-duration {
    margin-bottom: 5%;
}

.poll .poll-duration p {
    text-align: center;
}

.poll .poll-button {
    padding-bottom: 3%;
}

.poll .poll-button button {
    cursor: pointer;
    padding: 2%;
    color: #135463;
    border: 3px solid #135463;
    background-color: transparent;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(122, 122, 122);
}

.poll .poll-button button:hover {
    color: white;
    background-color: #135463;
    border: 3px solid transparent;
    transition: 0.5s;
}

.poll .poll-button button:active {
    transition: 0.5s;
    box-shadow: 3px 5px 5px rgb(44, 44, 44);
    transform: translate(0px, 2px);
}

@media (max-width: 480px) {
    .PollHome .center {
        padding: 2%;
        max-width: 100%;
    }

    .PollHome .poll {
        box-shadow: none;
        margin-bottom: 5%;
    }

    .PollHome .poll .poll-text {
        text-align: center;
    }
}


@media (max-width: 600px) {

    .PollHome .poll-button button {
        padding: 5%;
    }
}

@media (min-width: 601px) and (max-width: 767px) {}