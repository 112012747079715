.NewsContent {
    padding-top: 100px;
    min-height: 100vh;
    background-image: url("./../../assets/images/PLANODIRETORDEITAJUBACAPA2.JPG");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
}

.news-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news-title h1 {
    color: #e3edf3;
    margin-top: 3%;
    text-shadow: 2px 2px 1px rgb(73, 68, 68);
}

.news-title h3 {
    color: #e3edf3;
    text-align: center;
    text-shadow: 2px 2px 1px rgb(73, 68, 68);
}

.news-items {
    width: 100%;
    margin-top: 30px;
}

.news-items hr {
    margin-bottom: 30px;
}

.news-cards {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.news-card {
    background-color: #e3edf3;
    /* cursor: pointer; */
    width: 30%;
    border-radius: 10px;
    padding: 20px;
    margin-left: 2%;
    margin-right: 1%;
    margin-bottom: 30px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.3);
}

/* .news-card a {
    color: black;
    text-decoration: none;
} */

.news-card img {
    width: 100%;
}

.news-card h4 {
    margin-top: 10px;
}

.news-card h3 {
    margin-top: 10px;
    font-weight: bold;
}


.news-card {
    /* cursor: pointer; */
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.3);
}

.news-card .see-more {
    color: gray;
    text-decoration: underline;
}

p {
    white-space: pre-line;
}