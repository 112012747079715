.Footer {
    width: 100%;
    padding: 10px 0;
    background-color: #dfa92f;
}

.footer-items {
    width: 100%;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

.footer--links a {
    display: block;
    color: white;
    font-weight: 700;
    font-size: larger;
    text-decoration: none;
    transition: .25s all;
}

.footer--links a:hover {
    transition: .25s all;
    color: transparent;
    /* -webkit-text-stroke: .2px rgba(255, 255, 255, 0.39); */
    /* background: linear-gradient(135deg, rgba(221, 112, 9, 1) 11%, rgba(248, 180, 0, 1) 89%, rgba(237, 136, 2, 1) 100%); */
    background: linear-gradient(90deg, #5488a3 0%, #5488a3 35%, #5488a3 100%);
    -webkit-background-clip: text;
    background-clip: text;
    text-decoration: underline;
}

.footer--media {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer--media a {
    font-size: 35px;
    padding: 10px;
    text-decoration: none;
}

.footer--media .fa {
    display: inline;
    color: white;
    transition: .25s all;
}

.footer--media .fa:hover {
    transition: .25s all;
    -webkit-transition: .25s all;
    color: #5488a3 ;
}

.sopa {
    font-size: 40px;

    padding: 10px;
}

.footer--media .icon {
    /* background-image: url('../../assets/images/sopa_logo1.png'); */
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center;
}



.footer--copyright {
    color: white;
    transition: .25s all;
    text-align: right;
    font-weight: 700;
    font-size: larger;
}

/* .footer--copyright:hover {
    transition: .25s all;
    color: transparent;
    font-weight: 700;
    font-size: larger;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.11);
    background: linear-gradient(135deg, rgba(221, 112, 9, 1) 11%, rgba(248, 180, 0, 1) 89%, rgba(237, 136, 2, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
} */

@media (max-width:600px) {
    .footer-items {
        display: flex;
        flex-direction: column;
    }

    .footer--links {
        display: none;
    }

    .footer--copyright {
        margin-top: 5%;
        font-size: medium;
        text-align: center;
    }

}