.Plane {
    display: flex;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 50%, rgba(230, 230, 230, 1) 100%);
}

.plane-about {
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.plane--img img {
    width: 350px;
    height: 300px;
}

.plane--text {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 30px;

}

.plane--text h2 {
    text-align: center;
    padding-bottom: 30px;
}

.plane--text p {
    text-align: justify;
}

@media (max-width:600px) {
    .plane--text p {
        width: 100%;
        margin: 0;
        text-align: justify;
    }

    .plane--text h2 {
        margin: 0;
        text-align: center;
    }
}